import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import pairingReducer from './pairingSlice';
import userReducer from './userSlice';
import messageReducer from './messageSlice';
import filesReducer from './filesSlice';
import tabsReducer from './tabSlice';
// import { socketMiddleware } from './socketMiddleware'
const store = configureStore({
  reducer: {
    pairing: pairingReducer,
    user: userReducer,
    message: messageReducer,
    files: filesReducer,
    navigation: tabsReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(socketMiddleware),
})
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

