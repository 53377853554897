import SecurityIcon from '@mui/icons-material/Security';
import { Button } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Instructions from '../Instructions';
import ArticleIcon from '@mui/icons-material/Article';

const mdTheme = createTheme();
const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const [showInstructions, setShowInstructions] = useState(false);

  function handleCloseDialog() {
    setShowInstructions(false)
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <MuiAppBar position="absolute">
          <Toolbar>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              <SecurityIcon fontSize='small' /> Secured Site Services
            </Typography>

            <Button  color="inherit"  sx={{ mb: 1 }} onClick={() => setShowInstructions(true)} size={"large"} startIcon={<ArticleIcon />}>
              Show Instructions
            </Button>
          </Toolbar>
        </MuiAppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >

          <Toolbar />

          {children}
          {/* </Container> */}
        </Box>
      </Box>
      {showInstructions && <Instructions handleCloseDialog={handleCloseDialog} />}
    </ThemeProvider>
  );
}

export default DashboardLayout;