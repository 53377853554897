import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
// import { fetchCount } from './counterAPI';
import { getUser, login, logout } from '../api'


export type UserType = {
  username: string,
}
export interface UserStateType {
  authenticatedViaPairing: boolean | null;
  status: 'idle' | 'loading' | 'authenticated' | 'unauthenticated' | 'error';
  lastAuthRequest: null | { error: string }
  user: UserType | null,
}

const initialState: UserStateType = {
  authenticatedViaPairing: null,
  status: 'idle',
  lastAuthRequest: null,
  user: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.


export const getUserInfoAsync = createAsyncThunk(
  'user/getUserInfoAsync',
  async () => {
    return await getUser();
  }
);

export const loginAsyncThunk = createAsyncThunk(
  'user/loginAsync',
  async (input: { username: string }, storeAPI) => {
    await login(input);
    //if no error
    await storeAPI.dispatch(getUserInfoAsync());
  }
);

export const logoutAsyncThunk = createAsyncThunk(
  'user/logoutAsync',
  async (_ , storeAPI) => {
    await logout();
    //if no error
    await storeAPI.dispatch(getUserInfoAsync());
  }
);


export const counterSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {


    /*    increment: (state) => {
         // Redux Toolkit allows us to write "mutating" logic in reducers. It
         // doesn't actually mutate the state because it uses the Immer library,
         // which detects changes to a "draft state" and produces a brand new
         // immutable state based off those changes
         state.value += 1;
       },
       decrement: (state) => {
         state.value -= 1;
       },
       // Use the PayloadAction type to declare the contents of `action.payload`
       incrementByAmount: (state, action: PayloadAction<number>) => {
         state.value += action.payload;
       }, */
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //getUserInfoAsync
      .addCase(getUserInfoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'error';
        } else {
          state.authenticatedViaPairing = !!(action.payload.authenticatedViaPairing);
          state.status = (action.payload.isAuthenticated) ? "authenticated" : "unauthenticated";
          state.user = action.payload?.user;

        }

      })
      .addCase(getUserInfoAsync.rejected, (state) => {
        state.status = 'error';
      })


      //login
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        //todo
      })
  },
});

export const { } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUserState = (state: RootState) => state.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  }; */

export default counterSlice.reducer;