import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ErrorBoundary from './errorPage/ErrorBoundary';
import DashboardLayout from './layouts/DashboardLayout';
import { LoadingOverlayProvider } from './LoadingOverlayComponent/LoadingOverlayContext';
import Main from './Main';
import { ModalProvider } from './ModelComponent/ModalContext';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ErrorBoundary>
      <LoadingOverlayProvider>
        <ModalProvider>
          <BrowserRouter>


            <DashboardLayout>
              <Main />
            </DashboardLayout>

          </BrowserRouter>
        </ModalProvider>
      </LoadingOverlayProvider>

      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover />
    </ErrorBoundary>
  )
}




export default App;
