import { Fragment, SyntheticEvent } from 'react';

import { Box, ButtonGroup, Container, Tab, Tabs } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../utilties/redux/hooks';
import { getPairingInfoAsync, PairingStateType, selectParingState, unPairingAsync } from '../utilties/redux/pairingSlice';
import { logoutAsyncThunk, selectUserState, UserStateType } from '../utilties/redux/userSlice';

import React from 'react';
import FileList from './FileList';
import MessageBox from './MessageBox';
import UploadMediaAsset from './UploadMediaAsset';
import User from './User';
import { getTabState, setNavigationTab } from '../utilties/redux/tabSlice';

function PairedState() {
    // const [currentTabNumber, setCurrentTabNumber] = React.useState(0);
    const dispatch = useAppDispatch();

    const pairingState: PairingStateType = useAppSelector(selectParingState);

    const userState: UserStateType = useAppSelector(selectUserState);
    const tabState:number = useAppSelector(getTabState);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // setCurrentTabNumber(newValue);
        dispatch(setNavigationTab(newValue))
    };

    async function handleClickUnpair(e: SyntheticEvent) {
        e.preventDefault();
        await dispatch(unPairingAsync());
        //make sure user is logged out too
        await dispatch(logoutAsyncThunk());

        await dispatch(getPairingInfoAsync())
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, textAlign: "center" }}>
            <Paper sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                <Box>
                    <PairedAndLoginStatusComponent handleClickUnpair={handleClickUnpair} userState={userState} pairingState={pairingState} />

                    {userState.user && <Fragment>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabState} onChange={handleChange} centered>
                                <Tab label="Messages" />
                                <Tab label="Upload Files" />
                                <Tab label="Manage Files" />
                            </Tabs>
                        </Box>

                        <TabPanel value={tabState} index={0} >
                            <MessageBox />
                        </TabPanel>
                        <TabPanel value={tabState} index={1}>
                            <UploadMediaAsset />
                        </TabPanel>
                        <TabPanel value={tabState} index={2}>
                            <FileList />
                        </TabPanel>
                    </Fragment>}
                </Box>
            </Paper>
        </Container>
    );
}

export default PairedState;

///---

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


///---


const PairedAndLoginStatusComponent = ({ pairingState, handleClickUnpair, userState }: { userState: UserStateType, pairingState: PairingStateType, handleClickUnpair: any }) => {
    return (
        <Box sx={{ mt: 4 }}>
            {pairingState.status === "pairedAbove" && <Typography variant='h5'>Trusted Device</Typography>}
            {pairingState.status === "pairedBelow" && <Typography variant='h5'>Legacy Device</Typography>}

            <ButtonGroup sx={{ mt: 2 }}>
                {pairingState.status === "pairedAbove" && <User userState={userState} />}

                <Button onClick={handleClickUnpair} variant={"contained"}
                    startIcon={<SensorsOffIcon />}
                >Unpair devices</Button>
            </ButtonGroup>
        </Box>
    );
}


