import React from "react";
import ReactDOM from "react-dom";
import { LoadingOverlayContext } from "./LoadingOverlayContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingOverlay = () => {
  const { showLoadingOverlay, loadingOverlayActive } = React.useContext(LoadingOverlayContext);
  if (loadingOverlayActive) {
    return ReactDOM.createPortal(

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        onClick={()=>showLoadingOverlay(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      ,
      document.querySelector("#overlay-root")!
    );
  } else return null;
};

export default LoadingOverlay;
