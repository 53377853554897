
import axios from 'axios';
const BASE_URL = ''

export type GetUserResponseType = {
  authenticatedViaPairing: boolean
  isAuthenticated: boolean,
  user: any
};
export async function getUser(): Promise<GetUserResponseType | null> {
  return await (await axios.get(BASE_URL + '/api/auth/user'))?.data
}

export type PairingInfoResponse = {
  pairingType: "pairedBelow" | "pairedAbove",


};
//todo: deal with error
export async function getPairingInfo(): Promise<null | PairingInfoResponse> {
  return await (await axios.get(BASE_URL + '/api/pairing')).data?.pairing
}


export async function login(input:{username: string}): Promise<any> {
  try {
    const result = await axios.post(BASE_URL + '/api/auth/login', { username: input.username });
    return result?.data;
  }
  catch (e) {
    //todo: improve
    throw new Error((e as any)?.response?.message)
  }
}

export async function logout(): Promise<any> {
  try {
    const result = await axios.post(BASE_URL + '/api/auth/logout');
    return result?.data;
  }
  catch (e) {
    //todo: improve
    throw new Error((e as any)?.response?.message)
  }
}

export type MakePairingReturn = {
  "pairing": string,
  "success": boolean,
  "message": string
};
export async function makePairing(code: string): Promise<MakePairingReturn> {
  try {
    const result = await axios.post(BASE_URL + '/api/pairing', { code });
    return result?.data;
  }
  catch (e) {
    //todo: improve
    const message = (e as any)?.response?.data?.message
    throw new Error(message);
  }
}

export type UnpairReturn = {
  "success": boolean,
  "message": string
}
export async function unpair(): Promise<UnpairReturn> {
  return await (await axios.delete(BASE_URL + '/api/pairing'))?.data;
}

export type PairingCredentialsReturn = {
  code: string,
  expiryTimestamp: number
};

export async function getPairingCredentials(): Promise<null | PairingCredentialsReturn> {
  return await (await axios.post(BASE_URL + '/api/pairingcredentials'))?.data;
}

export async function sendMessageToPaired(message:string) {
   return await (await axios.post(BASE_URL + '/api/message-paired', {message}))?.data;
}


export async function createPresignedPost(filename:string, contentType:string) {
  return await (await axios.post(BASE_URL + '/api/upload-presigned-url', {filename, contentType}))?.data;
}


export async function getFilesList() {
  return await (await axios.get(BASE_URL + '/api/file'))?.data;
}

export async function getFileDownloadUrl(key:string) {
  return await (await axios.get(BASE_URL + '/api/file/'+encodeURIComponent(key)))?.data;
}


export async function deleteFile(key:string) {
  return await (await axios.delete(BASE_URL + '/api/file/'+encodeURIComponent(key)))?.data;
}

export async function uploadFile(file: File){
  //get upload url
  if(!file) throw new Error("Could not upload")

  const presignedPostData = await createPresignedPost(file.name, file.type);

  //upload file
  let form = new FormData();
  Object.keys(presignedPostData.fields).forEach(key => form.append(key, presignedPostData.fields[key]));
  form.append('file', file);

  // Send the POST request
  const response = await fetch(presignedPostData.url, { method: 'POST', body: form });
  console.log("response",response)
  if (!response.ok) throw new Error('Failed to upload');  
  return response;

}

export default {};