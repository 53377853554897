import { loginAsyncThunk, logoutAsyncThunk, selectUserState, UserStateType } from '../utilties/redux/userSlice';

import { Button, Paper, Typography } from '@mui/material';

import PersonIcon from '@mui/icons-material/AccountCircle';

import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../utilties/redux/hooks';
import { PairingStateType, selectParingState } from '../utilties/redux/pairingSlice';
import { Fragment } from 'react';

function User({userState}:{ userState:UserStateType}) {
    const dispatch = useAppDispatch();

    // const userState: UserStateType = useAppSelector(selectUserState);
    console.log(userState);
    const user = userState?.user;

    const pairingState: PairingStateType = useAppSelector(selectParingState);
    const pairingStatus = pairingState.status;

    async function handleLoginRequest() {
        const username = prompt("Enter Username");
        if (!username) {
            return alert("Invalid username");
        }
        // const password = prompt("Enter Password");
        try {
            dispatch(loginAsyncThunk({ username }))

        } catch (e) {
            alert((e as any).message)
        }
    }

    // if (user || !isPaired('pairedAbove')) { return "" }
    const loginButton = (
        <Button startIcon={<PersonIcon />} onClick={handleLoginRequest} size="large" variant={"contained"}>Login</Button>
    );


    const Authenticated = (
        <Fragment>

            <Button variant='outlined' startIcon={<PersonIcon/>} >Username: {user?.username}</Button>
            {!userState?.authenticatedViaPairing && <Button variant="contained" onClick={handleLogout} color={"error"} size={"medium"}>
                Logout User
            </Button>}

        </Fragment>
    );

    async function handleLogout() {
        dispatch(logoutAsyncThunk());
    }

    return (
        <Fragment>
       
            {userState.status !== 'authenticated' && pairingStatus === 'pairedAbove' && loginButton}
            {userState.status === 'authenticated' && Authenticated}

            </Fragment>
    )

}

export default User;