import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./ModalContext";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const LoadingOverlay = () => {
    const { isModalOpen, showModal, modalParams, setModalParams } = React.useContext(ModalContext);

    useEffect(() => {
        modalParams.body && showModal(true);
    }, [modalParams]);

    const handleClose = () => {
        showModal(false);
    };

    if (isModalOpen) {
        return ReactDOM.createPortal(
            <Dialog
                // fullScreen={fullScreen}
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                {modalParams.title &&
                    <DialogTitle>
                        {modalParams.title}
                    </DialogTitle>
                }
                <DialogContent>
                {modalParams.body &&
                    modalParams.body
                }
                    {/* <DialogContentText>
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    {modalParams.ok &&
                    <Button autoFocus onClick={handleClose}>
                        {modalParams.ok?.toUpperCase()}
                    </Button>
                    }
                    
                  
                </DialogActions>
            </Dialog>

            ,
            document.querySelector("#modal-root")!
        );
    } else return null;
};

export default LoadingOverlay;
