import { Container, Box, TextField, Chip, Card, Typography, Zoom, Stack, Snackbar, Alert } from '@mui/material';
import { Button } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';

import { useAppSelector, useAppDispatch } from '../utilties/redux/hooks';
import { sendMessageToPaired } from '../utilties/api';
import { getMessageState, MessageType, sendMessageThunk } from '../utilties/redux/messageSlice';
import { useState } from 'react';


export default function MessageBox() {
    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState("");
    const messageList: [MessageType] = useAppSelector(getMessageState);

    function handleClickSubmit(e: any) {
        e.preventDefault();
        //sent
        dispatch(sendMessageThunk(inputValue));
        //clear
        setInputValue("");
    }

    return (<Box sx={{ mt: 1 }}>
        <Card variant="outlined" onSubmit={handleClickSubmit}>

            <Box sx={{ m: 2 }} component={"form"}>
                <TextField id="filled-basic" label="Message paired" variant="outlined" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                <Button type='submit' sx={{ ml: 0.5, height: "3.4rem" }} color='success' variant="contained" endIcon={<SendIcon />} >send</Button>
            </Box>
        
            {messageList?.length > 0 &&
                <Stack spacing={1} >
                    {messageList?.map((message: MessageType, index: Number) => (
                            <Zoom in={true} key={""+index}><Box component="div" sx={{ transitionDuration: 10000, transitionProperty: 'initial' }}>
                            {message.sentBy === "incoming" &&
                                <Alert severity="success" icon={<FileDownloadIcon />} >{message.body}</Alert>
                            }

                            {message.sentBy === "outgoing" &&
                                <Alert severity="info" icon={<FileUploadIcon />} >{message.body}</Alert>  
                            }
                        </Box></Zoom>))}
                </Stack>
            }
        </Card>
    </Box>)
}