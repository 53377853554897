import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendMessageToPaired } from '../api';
import { RootState, AppThunk } from './store';
// import { fetchCount } from './counterAPI';
export type MessageType = {
  timeReceived: Date | string | null,
  body: string | null,
  sentBy: 'incoming' | 'outgoing' 
}

// export interface IMessageStateType {
//   timeReceived: Date | string | null,
//   body: string | null,
//   sentBy: 'trusted' | 'legacy' 
// }

const initialState: any = {
  messages: [
    
  //   {
  //   timeReceived: new Date().toISOString(),
  //   body: "action.payload",
  //   sentBy: 'outgoing'
  // },
      
  // {
  //   timeReceived: new Date().toISOString(),
  //   body: "action.payload",
  //   sentBy: 'incoming'
  // }
]
};

export const counterSlice = createSlice({
  name: 'message',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    messageReceived: (state, action: PayloadAction<string>) => {
      state.messages.push(
        {
          timeReceived: new Date().toISOString(),
          body: action.payload,
          sentBy:"incoming" 
        }
      )
      return state;
    },

    messageSent: (state, action:PayloadAction<string>) => {
      state.messages.push(
        {
          timeReceived: new Date().toISOString(),
          body: action.payload,
          sentBy:"outgoing" 
        }
      )
      return state;
    },
    clearMessage: () => {
      return initialState;
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    /*  builder
       //getPairingInfoAsync
       .addCase(getPairingInfoAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getPairingInfoAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = action.payload.pairingType;
           state.partnerSessionId = action.payload.sessionID;
           state.useragent = action.payload.useragent;
         }
 
       })
       .addCase(getPairingInfoAsync.rejected, (state) => {
         state.status = 'unpaired';
       })
 
       //makePairingCredentials
       .addCase(makePairingCredentials.pending, (state) => {
 
       })
       .addCase(makePairingCredentials.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = 'awaitingPairing';
           const pairingCredentials = { code: action.payload.code, expiryTimestamp: action.payload.expiryTimestamp };
           state.pairingCredentials = pairingCredentials;
         }
       })
 
       //requestPairing
       .addCase(requestPairingAsync.rejected, (state, action) => {
         const error = (action.error?.message) ? action.error?.message :'Pairing Failed';
         state.lastPairingRequest = {error};
       })
       .addCase(requestPairingAsync.pending, (state) => {
         state.lastPairingRequest = null;
       })
       .addCase(requestPairingAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.lastPairingRequest = { error: "Pairing failed" }
         }
 
         if (action.payload && action.payload.success) {
           //success
           state.status = 'pairedBelow';
         }
 
         if (action.payload && !action.payload.success) {
           state.status = 'unpaired';
           state.lastPairingRequest = { error: action.payload.message };
         }
       })
 
       .addCase(unPairingAsync.fulfilled, (state, action) => {
         if (action.payload && action.payload.success) {
           //success
           state = initialState;
         }
       }) */
  },
});

export const { messageReceived, clearMessage, messageSent } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getMessageState = (state: RootState) => state.message.messages;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
 export const sendMessageThunk =
  (message:string): AppThunk =>
  async (dispatch, getState) => {
    await sendMessageToPaired(message);
    dispatch(messageSent(message))
    // const currentValue = selectCount(getState());
    // if (currentValue % 2 === 1) {
    //   dispatch(incrementByAmount(amount));
    // }
  }; 

export default counterSlice.reducer;