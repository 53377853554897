import { Container, Box, TextField, Chip, Card, Typography, Zoom, Stack, Snackbar, Alert, ListItem, Avatar, IconButton, ListItemAvatar, ListItemText, List } from '@mui/material';
import { Button } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';

import { useAppSelector, useAppDispatch } from '../utilties/redux/hooks';
import { deleteFile, getFileDownloadUrl, getFilesList, sendMessageToPaired } from '../utilties/api';
import { getMessageState, MessageType, sendMessageThunk } from '../utilties/redux/messageSlice';
import { useEffect, useState } from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { DownloadForOfflineSharp, DownloadOutlined } from '@mui/icons-material';

import prettyBytes from 'pretty-bytes';
import type { S3Object } from '../types';
import { formatRelative } from 'date-fns';
import { getFileListState, getFileListThunk, removeFileThunk } from '../utilties/redux/filesSlice';


export default function FileList() {
    const dispatch = useAppDispatch();
    const fileLists: Record<string, [S3Object]> = useAppSelector(getFileListState);

    useEffect(
        () => {
            dispatch(getFileListThunk())
        }, []
    );


    return (<Box sx={{ mt: 1 }}>
        <Card variant="outlined" ><>
            <Typography sx={{ mt: 2, mb: 1 }} variant="h6" component="div">
                <div>Current user user has</div>



            </Typography>
            {Object.entries(fileLists).map(([visibility, list]) => <div key={visibility}>{list?.length} {visibility} files</div>)}


            {Object.entries(fileLists).map(([visibility, list]) => (
                <div>
                    <div>
                        <Typography variant="h6">{visibility}</Typography>
                    </div>
                    <List dense={true}>
                        {list?.map(
                            (item: S3Object, index) => <FileComponent key={index} item={item} handleRemove={() => dispatch(removeFileThunk(item.Key))} />
                        )}
                    </List>
                </div>
            ))}





        </></Card>
    </Box>)
}


function FileComponent({ item, handleRemove }: { item: S3Object, handleRemove: any }) {
    async function handleDownloadClick(key: string) {
        const data = await getFileDownloadUrl(key);
        window.open(data.url)
    }

    // async function handleDelete(key:string) {
    //     const data = await deleteFile(key);
    // }

    return (
        <ListItem button={false}
            secondaryAction={
                <div>
                    <IconButton edge="end" aria-label="download" onClick={() => handleDownloadClick(item.Key)}>
                        <DownloadForOfflineSharp />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" sx={{ ml: 1 }} onClick={handleRemove}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            }
        >
            <ListItemAvatar>
                <Avatar>
                    <FolderIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={item.Key?.split('/')[2]}
                secondary={prettyBytes(item.Size) + ' - ' +
                    formatRelative(new Date(item.LastModified), new Date())
                }
            />
        </ListItem>
    )

}