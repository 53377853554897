import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteFile, getFilesList, sendMessageToPaired } from '../api';
import { RootState, AppThunk } from './store';
// import { fetchCount } from './counterAPI';
import { S3Object } from '../../types';

export interface IFilesListStateType {
  files: Record<string, [S3Object]>
}

const initialState: any = {
  files: {}
};

export const counterSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    setFilesList:(state, action)=>{
      state.files = action.payload
    },
    removeFile:(state, action)=>{
      //todo: fix
      const removedKey = action.payload;
      state.files = Object.entries(state.files).reduce(
        (accumulator, [visiblity, files])=>{
          //@ts-ignore
          accumulator[visiblity] = files.filter((item:S3Object)=> item.Key !== removedKey )
          return accumulator
        }, {}
      )
      console.log(removedKey, state.files)
    }
  },
  extraReducers: (builder) => {
    /*  builder
       //getPairingInfoAsync
       .addCase(getPairingInfoAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getPairingInfoAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = action.payload.pairingType;
           state.partnerSessionId = action.payload.sessionID;
           state.useragent = action.payload.useragent;
         }
 
       })
       .addCase(getPairingInfoAsync.rejected, (state) => {
         state.status = 'unpaired';
       })
 
       //makePairingCredentials
       .addCase(makePairingCredentials.pending, (state) => {
 
       })
       .addCase(makePairingCredentials.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = 'awaitingPairing';
           const pairingCredentials = { code: action.payload.code, expiryTimestamp: action.payload.expiryTimestamp };
           state.pairingCredentials = pairingCredentials;
         }
       })
 
       //requestPairing
       .addCase(requestPairingAsync.rejected, (state, action) => {
         const error = (action.error?.message) ? action.error?.message :'Pairing Failed';
         state.lastPairingRequest = {error};
       })
       .addCase(requestPairingAsync.pending, (state) => {
         state.lastPairingRequest = null;
       })
       .addCase(requestPairingAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.lastPairingRequest = { error: "Pairing failed" }
         }
 
         if (action.payload && action.payload.success) {
           //success
           state.status = 'pairedBelow';
         }
 
         if (action.payload && !action.payload.success) {
           state.status = 'unpaired';
           state.lastPairingRequest = { error: action.payload.message };
         }
       })
 
       .addCase(unPairingAsync.fulfilled, (state, action) => {
         if (action.payload && action.payload.success) {
           //success
           state = initialState;
         }
       }) */
  },
});

export const { removeFile, setFilesList } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getFileListState = (state: RootState) => state.files.files

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
 export const getFileListThunk = (): AppThunk =>
  async (dispatch, getState) => {

    dispatch(setFilesList(await getFilesList()));
  }; 

  export const removeFileThunk = (key:string): AppThunk =>
  async (dispatch, getState) => {

    await deleteFile(key);
    dispatch(removeFile(key))
  }; 


export default counterSlice.reducer;