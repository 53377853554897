import { Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Instructions({ handleCloseDialog }: any) {
    return (
        <Dialog open={true} onClose={handleCloseDialog}>
            <DialogTitle>Secured Site Instructions</DialogTitle>
            <Divider/>
            <DialogContent>
                <DialogContentText component={"div"}>
                    This site supports user secured accessed by means of two legacy devices.  <br/>
                    Even if the two devices are infected with malware, the secured operation is maintained.
                    One device will regarded Trusted and the second device Legacy.    <br/>
                     Example of the two devices:    <br/>
                    Smartphone (Trusted) and notebook/tablet (Legacy).

                    <div>Currently supported features:</div>
                    <ul>
                        <li>Pairing of the two computers that access the site – “send pairing request” from the Trusted system</li>

                        <li>Open account to be accessed with a password</li>
                        <li>Verify paired devices</li>
                        <li>Validate Trusted message against Legacy message</li>
                    </ul>

                </DialogContentText>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Close</Button>
            </DialogActions>
        </Dialog>

    );
}