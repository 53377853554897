import { ThunkDispatch } from "@reduxjs/toolkit";

import { getPairingInfoAsync } from "./redux/pairingSlice";
import { getUserInfoAsync } from "./redux/userSlice";

import { clearMessage, messageReceived,} from './redux/messageSlice'
import { setNavigationTab } from "./redux/tabSlice";

import io from 'socket.io-client';

export interface IWebsocketEvent { "action": string, "domain": string };
const WEBSOCKETS_URL = process.env.REACT_APP_WEBSOCKETS_URL;

// let socket: WebSocket | null = null;
const socket = io();

export type PayloadType = {"action": string, "domain": string, "body": string};

export function initwebsockets(dispatch: any) {
    socket.on('connect', () => {
        // setIsConnected(true);
        console.log("connect")
      });

      socket.on('message', (payload:any) => {
        console.log(payload);
        console.log(`[message] Data received from server: ${JSON.stringify(payload)}`);

        if (payload && payload.domain === 'pairing' && payload.action === 'update') {
            dispatch(getPairingInfoAsync());
            dispatch(getUserInfoAsync());
            dispatch(clearMessage());
        }

        if (payload && payload.domain === 'user' && payload.action === 'update') {
            dispatch(getUserInfoAsync());
        }

        if (payload && payload.domain === 'message' && payload.action === 'message') {
            console.log("messageReceived...")
            dispatch(messageReceived(payload.body));
            dispatch(setNavigationTab(0))
        }

      });
}

/* export function verifyWebsockets(dispatch: any) {
// 0	CONNECTING	Socket has been created. The connection is not yet open.
// 1	OPEN	The connection is open and ready to communicate.
// 2	CLOSING	The connection is in the process of closing.
// 3	CLOSED	The connection is closed or couldn't be opened.
    // if(!socketx || socketx.readyState > 1 ){ 
    //     console.log("trying to connect to websocket");
    //     initwebsockets(dispatch);
    // }
} */

export { }