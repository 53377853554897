import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ErrorIcon from '@mui/icons-material/Error';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


const theme = createTheme();

export default function ErrorView() {


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}  
>
       
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square  sx={{background:'#eee'}} > 
          <Box
            sx={{
              mt: "47%",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              
         
            }}
          >
            <Avatar sx={{ mb: 3, bgcolor: '#d84315'  }}>
              <PriorityHighIcon fontSize='large'/>
            </Avatar>
            <Typography component="h1" variant="h4">
                Sorry, there was an error.
            </Typography>
           
          </Box>
        </Grid>

        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random/?wiring)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />

      </Grid>
    </ThemeProvider>
  );
}