import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
// import { fetchCount } from './counterAPI';
import { getUser, makePairing, getPairingInfo, unpair, login, getPairingCredentials, logout } from '../api'

export interface PairingStateType {
  status: 'loading' | 'unpaired' | 'awaitingPairing' | 'pairedBelow' | 'pairedAbove';
  pairingCredentials: null | { expiryTimestamp: number, code: string },
  lastPairingRequest: null | { error: string }
}

const initialState: PairingStateType = {
  status: 'unpaired',
  pairingCredentials: null,
  lastPairingRequest: null
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPairingInfoAsync = createAsyncThunk(
  'pairing/fetchState',
  async () => {
    return await getPairingInfo();
    // const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    // return response.data;
  }
);

export const makePairingCredentials = createAsyncThunk(
  'pairing/makePairingCredentials',
  async () => {
    return await getPairingCredentials();
  }
);


export const requestPairingAsync = createAsyncThunk(
  'pairing/requestPairingAsync',
  async (code: string) => {
    return await makePairing(code);
  }
);


export const unPairingAsync = createAsyncThunk(
  'pairing/unPairingAsync',
  async () => {
    return await unpair();
  }
);


export const counterSlice = createSlice({
  name: 'pairing',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {


    /*    increment: (state) => {
         // Redux Toolkit allows us to write "mutating" logic in reducers. It
         // doesn't actually mutate the state because it uses the Immer library,
         // which detects changes to a "draft state" and produces a brand new
         // immutable state based off those changes
         state.value += 1;
       },
       decrement: (state) => {
         state.value -= 1;
       },
       // Use the PayloadAction type to declare the contents of `action.payload`
       incrementByAmount: (state, action: PayloadAction<number>) => {
         state.value += action.payload;
       }, */
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //getPairingInfoAsync
      .addCase(getPairingInfoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPairingInfoAsync.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'unpaired';
        } else {
          state.status = action.payload.pairingType;
          // state.partnerSessionId = action.payload.sessionID;
          // state.useragent = action.payload.useragent;
        }

      })
      .addCase(getPairingInfoAsync.rejected, (state) => {
        state.status = 'unpaired';
      })

      //makePairingCredentials
      .addCase(makePairingCredentials.pending, (state) => {

      })
      .addCase(makePairingCredentials.fulfilled, (state, action) => {
        if (!action.payload) {
          state.status = 'unpaired';
        } else {
          state.status = 'awaitingPairing';
          const pairingCredentials = { code: action.payload.code, expiryTimestamp: action.payload.expiryTimestamp };
          state.pairingCredentials = pairingCredentials;
        }
      })

      //requestPairing
      .addCase(requestPairingAsync.rejected, (state, action) => {
        const error = (action.error?.message) ? action.error?.message :'Pairing Failed';
        state.lastPairingRequest = {error};
      })
      .addCase(requestPairingAsync.pending, (state) => {
        state.lastPairingRequest = null;
      })
      .addCase(requestPairingAsync.fulfilled, (state, action) => {
        if (!action.payload) {
          state.lastPairingRequest = { error: "Pairing failed" }
        }

        if (action.payload && action.payload.success) {
          //success
          state.status = 'pairedBelow';
        }

        if (action.payload && !action.payload.success) {
          state.status = 'unpaired';
          state.lastPairingRequest = { error: action.payload.message };
        }
      })

      .addCase(unPairingAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          //success
          state = initialState;
        }
      })
  },
});

export const {  } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectParingState = (state: RootState) => state.pairing;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/* export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  }; */

export default counterSlice.reducer;