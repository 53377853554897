import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface INavigationTabType {
  tab: number 
}

const initialState: INavigationTabType = {
  tab: 0
};

export const counterSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigationTab:(state, action)=>{
      state.tab = action.payload
    }
  },
  extraReducers: (builder) => {
    /*  builder
       //getPairingInfoAsync
       .addCase(getPairingInfoAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getPairingInfoAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = action.payload.pairingType;
           state.partnerSessionId = action.payload.sessionID;
           state.useragent = action.payload.useragent;
         }
 
       })
       .addCase(getPairingInfoAsync.rejected, (state) => {
         state.status = 'unpaired';
       })
 
       //makePairingCredentials
       .addCase(makePairingCredentials.pending, (state) => {
 
       })
       .addCase(makePairingCredentials.fulfilled, (state, action) => {
         if (!action.payload) {
           state.status = 'unpaired';
         } else {
           state.status = 'awaitingPairing';
           const pairingCredentials = { code: action.payload.code, expiryTimestamp: action.payload.expiryTimestamp };
           state.pairingCredentials = pairingCredentials;
         }
       })
 
       //requestPairing
       .addCase(requestPairingAsync.rejected, (state, action) => {
         const error = (action.error?.message) ? action.error?.message :'Pairing Failed';
         state.lastPairingRequest = {error};
       })
       .addCase(requestPairingAsync.pending, (state) => {
         state.lastPairingRequest = null;
       })
       .addCase(requestPairingAsync.fulfilled, (state, action) => {
         if (!action.payload) {
           state.lastPairingRequest = { error: "Pairing failed" }
         }
 
         if (action.payload && action.payload.success) {
           //success
           state.status = 'pairedBelow';
         }
 
         if (action.payload && !action.payload.success) {
           state.status = 'unpaired';
           state.lastPairingRequest = { error: action.payload.message };
         }
       })
 
       .addCase(unPairingAsync.fulfilled, (state, action) => {
         if (action.payload && action.payload.success) {
           //success
           state = initialState;
         }
       }) */
  },
});

export const { setNavigationTab } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getTabState = (state: RootState) => state.navigation.tab

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

 

export default counterSlice.reducer;