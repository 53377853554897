import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDropzone, DropzoneProps, FileRejection, DropEvent, FileError } from 'react-dropzone';

import { Container, Button, TextField, Grid, Box, Stack, Chip, List, Divider, ListItem, ListItemText, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useAppDispatch } from '../utilties/redux/hooks';

import { toast } from 'react-toastify';
import { LoadingOverlayContext } from "./LoadingOverlayComponent/LoadingOverlayContext";
import { uploadFile } from '../utilties/api';
import prettyBytes from 'pretty-bytes';
import { setNavigationTab } from '../utilties/redux/tabSlice';

const UPLOAD_MAX = +process.env.REACT_APP_UPLOAD_MAX!;

const theme = createTheme();

export default function UploadMediaAsset() {
    // const navigate = useNavigate();
    const { showLoadingOverlay } = React.useContext(LoadingOverlayContext);
    const dispatch = useAppDispatch();


    const handleDroppedFiles = async (acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent) => {
        if (fileRejections.length) {
            console.log("fileRejections[0]", fileRejections[0])

            toast.error(`Error: ${fileRejections[0]?.errors[0]?.message}`)
            return;
        }


        if (!acceptedFiles.length) return;
        showLoadingOverlay(true);
        //todo: check file size acceptedFiles[0].size
        try {
            await uploadFile(acceptedFiles[0]);
            toast.success(`${acceptedFiles?.length} file uploaded.`)

            setTimeout(
                () => dispatch(setNavigationTab(2))
                , 1000);

        } catch (error: unknown) {
            toast.error((error as Error).message)
        } finally {
            showLoadingOverlay(false)
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <Container component="main" >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center'
                    }}
                >

                    <Box >
                        <Typography component="h1" variant="h5">
                            <PermMediaIcon sx={{ transform: "translateY(5px)" }} />  Upload Files
                        </Typography>
                        <Typography variant='body2'>This is for demo purposes. Do not upload private documents.</Typography>
                        <Typography variant='body2'>Warning: All files will be deleted after one day!</Typography>
                        <Typography gutterBottom variant='body2'>Maximum file upload size is {prettyBytes(+UPLOAD_MAX!)}</Typography>
                        <Dropzone handleDroppedFiles={handleDroppedFiles} maxSize={(+UPLOAD_MAX!)!} />
                    </Box>
                </Box>

            </Container>
        </ThemeProvider >
    );
}


function Dropzone(props: any) {
    const { handleDroppedFiles } = props;
    const baseStyle = {
        flex: 1,
        display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',

        padding: '40px',
        borderWidth: 4,
        borderRadius: 7,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        // color: '#999',
        outline: 'none',
        transition: 'border .24s ease-in-out',

    };

    const focusedStyle = {
        borderColor: '#2196f3',
        backgroundColor: "#bcbcbc"
    };

    const acceptStyle = {
        borderColor: '#00e676',
        backgroundColor: "#bcbcbc"
    };

    const rejectStyle = {
        // borderColor: '#ff1744',
        backgroundColor: '#ffb2b2'
    };

    const onDrop = useCallback(handleDroppedFiles, [])
    const {
        isFocused,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections
    } = useDropzone({ onDrop, multiple: false, maxSize: UPLOAD_MAX })

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (

        <Container {...getRootProps({ style })} sx={{ marginTop: 3, width: '100%' }}>
            <input {...getInputProps()} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Box sx={{ minWidth: '500px' }}>
                    {
                        isDragActive ?
                            <p>Drop the file here</p> :
                            <p>Drag and drop file here, or click</p>
                    }
                </Box>
                <CloudUploadIcon sx={{ fontSize: '62px', color: '#999' }} />
            </Box>

        </Container>
    )
}

