import { Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../utilties/redux/hooks';
import { getPairingInfoAsync, PairingStateType, selectParingState } from '../utilties/redux/pairingSlice';
import { getUserInfoAsync } from '../utilties/redux/userSlice';
import { initwebsockets } from '../utilties/websocket';
import FilesDashboard from './FilesDashboard';
import PairedState from './PairedState';
import UnpairedState from './UnpairedState';

function Main() {
    const dispatch = useAppDispatch();
    const pairingState: PairingStateType = useAppSelector(selectParingState);
    const pairingStatus = pairingState.status;

    useEffect(
        () => {
            dispatch(getPairingInfoAsync())
            dispatch(getUserInfoAsync())
            initwebsockets(dispatch);

            // const timer1 = setTimeout(
            //     () => {
            //         initwebsockets(dispatch);
            //     },
            //     200
            // );
    

            return () => {
                // clearInterval(timer1);
            }
        }, []
    );


    if(['unpaired', 'awaitingPairing'].includes(pairingStatus)){
        return (
            <UnpairedState />
        )
    }

    if(['pairedBelow', 'pairedAbove'].includes(pairingStatus)){
        return (
            <PairedState />
        )
    }

    return (<></>)

}

export default Main;