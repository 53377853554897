import React from "react";
import ModalOverlay from "./ModalOverlay";

const ModalContext = React.createContext(({} as any))

const ModalProvider: any = ({ children }:any) => {
  const [isModalOpen, showModal] = React.useState<boolean>(false);
  const [modalParams, setModalParams] = React.useState<{body: React.ElementType | string | null, title: string | null, ok: string | null }>({
    body: null,
    title: null,
    ok: null
  });


  return (
    <ModalContext.Provider value={{ showModal, isModalOpen, modalParams, setModalParams  }}>
      <ModalOverlay />
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
