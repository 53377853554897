import { Fragment, useState } from 'react';

import { Box, Chip, Container, TextField } from '@mui/material';
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import NotificationsIcon from '@mui/icons-material/Notifications';


import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

// import { getUser, makePairing, getPairingInfo, unpair, login, getPairingCredentials, logout } from '../utilties/api';
import { Button } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../utilties/redux/hooks';
import { getPairingInfoAsync, makePairingCredentials, PairingStateType, requestPairingAsync, selectParingState, unPairingAsync } from '../utilties/redux/pairingSlice';
// import { initwebsockets  } from '../utilties/websocket';

function UnpairedState() {
    const dispatch = useAppDispatch();

    const pairingState: PairingStateType = useAppSelector(selectParingState);

    const [formData, setFormData] = useState<Record<string, any>>({ code: '' });

    async function handleMakepairing(e: any) {
        e.preventDefault();
        const code = formData.code;
        try {
            dispatch(requestPairingAsync(code));
            //all good, then reset form
            setFormData({ ...formData, code: '' });
        } catch (e) {
            console.error(e);
            alert((e as any));
        }
    }

    function handleFormChange(event: any): void {
        const { name, value, type, checked } = event.target
        setFormData((prevFormData: any) => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    };

    async function handleGetPairingCredentials() {
        dispatch(makePairingCredentials());
        // verifyWebsockets(dispatch);
    }

    function AwaitingParingRequest() {
        return (<>
            <Typography>
                For device pairing, enter this code on the legacy device:
                <div>
                    <Chip label={pairingState?.pairingCredentials?.code} color="primary" variant="filled" sx={{ fontSize: "larger" }} />
                </div>
            </Typography>

            <Typography>
                This code is valid until {new Date(pairingState?.pairingCredentials?.expiryTimestamp!).toLocaleString()}
            </Typography>
        </>)
    }

    const pairingInputForm = (
        <Box sx={{ mt: 2 }}>
            <form>
                <TextField sx={{ mr: 1 }}
                    value={formData.code}
                    size='small'
                    id="pairing-code-input"
                    label="Enter Pairing Code"
                    autoComplete="off"
                    onChange={handleFormChange}
                    name="code"
                    error={!!(pairingState.lastPairingRequest?.error)}
                    helperText={pairingState.lastPairingRequest?.error ? "Error: " + pairingState.lastPairingRequest?.error : ''}
                />

                <Button disabled={formData.code.length === 0} type="submit" onClick={handleMakepairing} size="large" variant={"contained"}>Pair</Button>
            </form>
        </Box>);

    return (
        <Container maxWidth="xs" sx={{ mt: 4, textAlign:"center" }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Box>
                    {pairingState.status === 'unpaired' &&
                        <div>
                
                            <Button variant="contained" onClick={handleGetPairingCredentials} color={"secondary"} size={"large"} startIcon={<SettingsRemoteIcon />}>
                                Send Pairing Request
                            </Button>
                            {pairingInputForm}
                        </div>}

                    {pairingState.status == 'awaitingPairing' && <AwaitingParingRequest />}
                </Box>
            </Paper>
        </Container>
    );
}

export default UnpairedState;