import React, {FC} from "react";
import LoadingOverlay from "./LoadingOverlay";

const LoadingOverlayContext = React.createContext(({} as any))

const LoadingOverlayProvider: any = ({ children }:any) => {
  const [loadingOverlayActive, showLoadingOverlay] = React.useState<boolean>(false);

  return (
    <LoadingOverlayContext.Provider value={{ showLoadingOverlay, loadingOverlayActive }}>
      <LoadingOverlay />
      {children}
    </LoadingOverlayContext.Provider>
  );
};

export { LoadingOverlayContext, LoadingOverlayProvider };
